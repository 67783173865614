<template>
  <div>
    <Card>
      <template #header>Kira Kayıtları</template>
      <div class="d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
          <Input placeholder="Ara.." v-model="search" class="mr-2" />
        </div>
        <div class="d-flex align-items-center">
          <b-form-group>
            <b-button variant="primary" @click="addExper = true"
              >Yeni</b-button
            ></b-form-group
          >
        </div>
      </div>
    </Card>

    <List
      :header="header"
      :data="items"
      :pagination="pagination"
      :loading="loading"
      @updatePage="updatePage"
      moduleName="Sistem Kullanıcı Listesi"
      :moduleCreateEvent="true"
      :moduleSearch="search"
    >
      <template v-slot:item.action="{ item }">
        <div class="d-flex">
          <button class="btn p-0">
            <i class="flaticon-eye text-dark text-md"></i>
          </button>
          <button class="btn p-0">
            <i class="flaticon2-trash text-dark text-md"></i>
          </button>
          <button class="btn p-0">
            <i class="flaticon-download text-dark text-md"></i>
          </button>
        </div>
      </template>
    </List>
    <b-modal v-model="addExper" id="note-modal">
      <template #modal-header>
        <h4><i class="flaticon2-plus mr-4"></i>Yeni Ekspertiz Kayıt Ekleme</h4>
      </template>
      <div class="row">
        <div class="col-md-6 col-12">
          <div class="form-group">
            <label for="">Sözleşme Tarihi</label>
            <input type="date" class="form-control" />
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <label for="">Sözleşme Süresi </label>
            <textarea name="" id="" rows="1" class="form-control"></textarea>
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <label for="">Ödeme Zamanı</label>
            <textarea name="" id="" rows="1" class="form-control"></textarea>
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <label for="">Kiralama Bedeli</label>
            <textarea name="" id="" rows="1" class="form-control"></textarea>
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <label for="">Kira Başlangıç Bedeli </label>
            <textarea name="" id="" rows="1" class="form-control"></textarea>
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <label for="">Kiracı Ad Soyad / Ünvan </label>
            <textarea name="" id="" rows="1" class="form-control"></textarea>
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <label for="">Kiracı Telefon </label>
            <textarea name="" id="" rows="1" class="form-control"></textarea>
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <label for="">Kiracı TV/VKN </label>
            <textarea name="" id="" rows="1" class="form-control"></textarea>
          </div>
        </div>

        <div class="col-12">
          <div class="form-group">
            <label for="">Açıklama </label>
            <textarea name="" id="" rows="4" class="form-control"></textarea>
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <label for="">Kullanıcı</label>
            <textarea name="" id="" rows="1" class="form-control"></textarea>
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <b-form-checkbox  name="check-button" switch>
                Ödendi mi? 
            </b-form-checkbox>
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <label for="">Ödendiği Tarih</label>
            <input type="date" class="form-control" />

          </div>
        </div>
      </div>

      <template #modal-footer>
        <div class="d-flex justify-content-end w-100">
          <b-button variant="light" @click="addExper = false">
            Vazgeç
          </b-button>
          <b-button variant="success" @click="addNewNote" class="ml-4">
            Kaydet
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "systemUsers",
  data() {
    return {
      addExper: false,
      search: "",
      filters: "",
      showInsertUpdate: false,
      showDetail: false,
      showImportModal: false,
      resetForm: false,
      confirmCreate: false,
      loading: false,
      header: [
        { text: "Sözleşme Tarihi", value: "aciklama" },
        { text: "Sözleşme Süresi", value: "aciklama" },
        { text: "Ödeme Zamanı", value: "tip" },
        { text: "Kira Bedeli", value: "tarih" },
        { text: "Kiracı Ünvan", value: "tarih" },
        { text: "Kiracı Telefon", value: "tarih" },
        { text: "Kiracı TC/VKN", value: "tarih" },
        { text: "İşlemler", value: "action" },
      ],
      items: [],
      pagination: {},
      meta: {},
      listSelectedItemIds: [],
      selectedItem: "",
      deleteOperationUrl: "",
      deleteIds: "",
    };
  },
  
  created() {
    this.getList();
  },
  methods: {
    getList() {
      this.loading = true;

      this.items = [
        {
          id: 1,
          aciklama: "test beyan 05.07.2023",
          tip: "Diğer",
          tarih: "01-01-2023",
        },
      ];
      this.totalItems = 3;

      this.pagination = {
        current_page: 1,
        total_pages: 1,
        total_items: 3,
      };
      this.loading = false;

      // this.items = [];
      // this.totalItems = 0;

      // let queryLimit = this.perPage ? this.perPage : 10;
      // let query = `user/list?Page=${
      //   this.page ? this.page : 1
      // }&limit=${queryLimit}`;
      // if (this.search) {
      //   query += `&search=${this.search}`;
      // }

      // this.$dbFunctions
      //   .get(query)
      //   .then((res) => {
      //     if (res.success == true && res.count > 0) {
      //       this.items = res.data;
      //       this.totalItems = res.count;

      //       this.pagination = {
      //         current_page: res.page,
      //         total_pages: res.pageCount,
      //         total_items: res.count,
      //       };
      //     }
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   })
      //   .finally(() => {
      //     this.loading = false;
      //   });
    },
    updatePage(value) {
      this.meta = value;

      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
  watch: {
    meta: {
      handler() {
        this.getList();
      },
      deep: true,
    },
    search: function (params) {
      this.getList();
    },
  },
};
</script>
